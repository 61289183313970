<template>
  <section class="form-container">
    <section class="form-item m-t-18">
      <a-form :form="form" layout="inline">

        <section v-if="originType == 1">
          <a-row>
            <a-form-item label="文档标题">
              {{navDetail.topic}}
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="文档类型">
              <div>{{navDetail.contentType==1?'文本':navDetail.contentType==2?'图片':'视频'}}</div>
            </a-form-item>
          </a-row>
          <a-row v-if="navDetail.contentType == 1">
            <a-form-item label="文档内容">
              <div>{{navDetail.content}}</div>
            </a-form-item>
          </a-row>
          <a-row v-else-if="navDetail.contentType == 2">
            <a-row
              v-for="(item, key) in navDetail.fileUrl"
              :key="key"
              class="text-center"
            >
              <img width="880px" :src="item.url" class="m-b-10" />
            </a-row>
          </a-row>
          <a-row v-else>
            <a-form-item label="文档视频">
              <a-row>
              <video
                v-if="navDetail.fileUrl && navDetail.fileUrl.length"
                style="width: 1000px"
                autoplay
                controls="controls"
              >
                <source :src="navDetail.fileUrl[0].url" type="video/mp4" />
              </video>
            </a-row>
            </a-form-item>
          </a-row>
        </section>

        <a-row>
          <a-form-item label="投放位置">
              {{navDetail.publicizePosition==1?'项目概览':navDetail.publicizePosition==2?'宣传快讯':navDetail.publicizePosition==3?'通知公告':'LED屏'}}
          </a-form-item>
        </a-row>

        <a-row v-if="navDetail.publicizePosition==4">
          <a-form-item label="对应设备">
            {{navDetail.deviceName}}
          </a-form-item>
        </a-row>

        <!-- <a-row v-if="originType == 2">
          <a-form-item label="选择文档">
            <a-select
              style="width: 300px"
              placeholder="请选择"
              v-decorator="[
                'resourceId',
                { rules: [{ required: true, message: '请输入角色名称' }] },
              ]"
            >
              <a-select-option
                v-for="(item, ind) in newsList"
                :key="ind"
                :value="item.id"
                >{{ item.topic }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-row> -->

        <a-row>
          <a-form-item label="展示排序">
            <div>{{navDetail.sort}}</div>
          </a-form-item>
        </a-row>


        <a-row class="form-btn-group" style="padding-left: 80px;text-align:center;">
          <a-button class="btn-cancel" @click="$router.back()">返回</a-button>
        </a-row>
      </a-form>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      fileLists: [],
      resourceType: 1,
      originType: 1,
      newsList: [], //文本列表
      teamMaplist: [],
      valueType:4,
      navDetail:{},
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    let navDetail= JSON.parse(localStorage.getItem('navDetail'))
    console.log(navDetail)
    if(navDetail.fileUrl){
        navDetail.fileUrl = JSON.parse(navDetail.fileUrl)
    }
    this.navDetail= navDetail


    this.queryContentSetList();
    this.getPublicizeDeviceList();

    if (this.originType == 1) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          resourceType: 1,
        });
      });
    }
  },
  computed: {
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  methods: {
    clickCg(e) {
      this.valueType=e.target.value
    },
    getPublicizeDeviceList() {
      this.$api.getPublicizeDeviceList().then((res) => {
        if (res.code === 200) {
          this.teamMaplist = res.data || [];
        }
      });
    },
    queryContentSetList() {
      this.$api.queryContentSetList({ size: 500 }).then((res) => {
        if (res.code === 200) {
          this.newsList = res.data || [];
        }
      });
    },
    changeOriginType() {
      if (this.originType == 1) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            resourceType: 1,
          });
        });
      }
    },
    changeType(val) {
      this.resourceType = val;
    },
    uploadFile(file) {
      if (this.resourceType == 2) {
        this.fileLists.push(file);
      } else {
        this.fileLists = [file];
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          fileUrl: this.fileLists.length ? JSON.stringify(this.fileLists) : "",
        });
      });
    },
    deleteFile(list) {
      this.fileLists = this.fileLists.filter(
        (item) => item.codeUrl != list.codeUrl
      );
    },
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          this.$store.commit("SET_SUBMITLOADDING", true);
          let apiName = formData.resourceId
            ? "savePublicizePlan"
            : "addAndSavePublicize";
          let params = formData.resourceId ? [formData] : formData;
          this.$api[apiName](params).then((res) => {
            if (res.code === 200) {
              this.$router.back();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.radioys {
  .ant-radio {
    margin-top: 10px;
  }
}
</style>